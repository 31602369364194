import React from "react"
import {navigate} from "gatsby"
import {Link} from "gatsby"
import {handleLogin, isLoggedIn, dashboardPath} from "../services/auth"

class LoginForm extends React.Component {
    state = {
        email: ``, password: ``, error: ``
    }

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = async event => {
        event.preventDefault()
        var lgres = await handleLogin(this.state)
        if (lgres === false) {
            this.setState((state, props) => {
                return {email: state.email, password: "", error: "The password that you've entered is incorrect."};
            });
        } else {
            const redirect = window.localStorage.getItem('redirectUrl');
            if (redirect !== null) {
                window.localStorage.removeItem('redirectUrl')
                navigate(redirect)
            } else {
                navigate(dashboardPath())
            }
        }
    }

    render() {
        if (isLoggedIn()) {
            navigate(dashboardPath())
        }

        return (<>
            <form className='contactform pl-0' method="post" onSubmit={this.handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email Address</label>
                    <input type="text" name="email" className="form-control" onChange={this.handleUpdate}/>
                </div>
                <div className="mb-2">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" name="password" className="form-control" onChange={this.handleUpdate}/>
                    <p className="text-left mb-1"><Link className="pinkcolor font-600" to="/forget-password/user">Forgot Password?</Link></p>
                </div>
                {this.state.error !== "" && <div className="err">{this.state.error}</div>}
                <div className="mb-3 text-center">
                    <button className="slide_from_left submitformbtn col-md-8 col-lg-5 col-sm-8" type="submit">
                            <span className="btnicon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.381" height="20.379" viewBox="0 0 31.381 31.379"><path id="Path_370" data-name="Path 370"
                                                                                                                                 d="M647.7,399.482l-22.405-6-.039-.012c-2.395-.644-3.713.045-4.385.725-.691.68-1.38,2-.737,4.394l.012.041v0l6,22.4a4.58,4.58,0,0,0,2.981,3.454,3.08,3.08,0,0,0,.791.1,3.394,3.394,0,0,0,2.394-1.058l5.23-5.233,6.211.1h.021a1.285,1.285,0,0,0,.9-.372l.005,0c.023-.026.033-.059.055-.085a1.225,1.225,0,0,0,.217-.327,1.249,1.249,0,0,0,.048-.22,1.211,1.211,0,0,0,.056-.255c0-.008,0-.014,0-.02a.159.159,0,0,0,0-.021l-.093-6.211,5.232-5.233a3.253,3.253,0,0,0,.956-3.179,4.581,4.581,0,0,0-3.455-2.985m-25.016-3.475c.2-.2.75-.311,1.663-.11l18.056,15.066.072,4.845-4.844-.074-15.068-18.056c-.2-.913-.09-1.463.121-1.671"
                                                                                                                                 transform="translate(-619.881 -393.21)" fill="#fff"/></svg>
                    </span>
                        <span className="imagectabtnlable">Log In</span>
                    </button>
                </div>
            </form>
            <p className="text-center font-600">Don't have account? <Link className="pinkcolor" to="/signup">Sign-up Here</Link></p>
        </>)
    }
}

export default LoginForm
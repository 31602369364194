import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import LoginForm from "../components/loginform"
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const LoginPage = () => {
    //TODO:: Redirect user to myaccount page if they are already logged in
    return (
        <main>
            <Header/>
            <AruzySeo title={"Login"}/>
            <div className="App topattachbgsection">
                <div className="container  changeheading pt-3 pb-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center">
                                <HeaderTitle title={'LOGIN'}/>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-8 col-sm-12">
                                    <LoginForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default LoginPage